<template>
    <section id="workingArea">
        <div class="gdnBorder">
            <div class="pools" >
                <div class="inner">
                    <div class="align-items-center">
                        <h3 style="font-size:18px;">All Farms <br><br>
                        <small>Stake your LP tokens and earn token rewards</small></h3>
                    </div>
                    <div id="tableCont">
                        <q-table
                                title=""
                                :dense="$q.screen.lt.md"
                                class="transparent custom-table"
                                :data="filteredFarm()"
                                :columns="columns"
                                row-key="id"
                                v-if="!isMobile"
                                :pagination.sync="pagination"
                                hide-bottom
                                binary-state-sort
                                :sort-method="mySort"
                        >
                        <template v-slot:body="props">
                            <q-tr :props="props" @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" 
                                class="cursor-pointer">
                                <q-td>
                                    <div class="star flex items-center no-wrap">
                                        <i>
                                            <svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </i>
                                        <span class="mr-2 avatar">
                                            <img :src="getLogo(0, props.row.id)" alt="">
                                        </span>
                                        <span class="usdc avatar">
                                            <img :src="getLogo(1, props.row.id)" alt="">
                                        </span>
                                        {{getPairName(props.row.id)}}
                                    </div>
                                </q-td>
                                <q-td class="text-right">
                                    {{pendingSTAR(props.row.id)}} <span class="token">STAR</span>
                                </q-td>
                                <q-td class="text-right">
                                    ${{getTVL(props.row.id).toFormat(2)}}<br>
                                    {{getTotalLPSupply(props.row.id)}} <span class="token">LP</span>
                                </q-td>
                                <q-td class="text-right">
                                    {{getAPR(props.row.id).toFormat(2)}}<span class="token">%</span>
                                </q-td>
                                <q-td>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" style="enable-background:new 0 0 284.929 284.929;" xml:space="preserve">
                                        <g>
                                            <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                                L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                                c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                                c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path>
                                        </g>
                                    </svg>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props">
                                <q-td colspan="5" style="border-radius:20px" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-pa-sm" 
                                        style="font-size 0.5rem;">
                                        <div class="text-center">
                                            <span class="label" >Total Reward</span><br>
                                                {{pendingSTAR(props.row.id)}} <span class="token">STAR</span>
                                        </div>
                                        <div class="text-center tooltip2">
                                            <span class="label" >Reward After Tax</span><br>
                                                {{claimableSTAR(props.row.id)}} <span class="token">STAR
                                            </span>
                                            <span class="tooltiptext">
                                                <p class="note">
                                                    Harvesting is taxed and tax<br />decreases 1% every day. You<br />can harvest today, but will<br />lose a good % of the reward!
                                                </p>
                                            </span>
                                        </div>
                                        <div class="text-center">
                                            <span class="label" >Reward Claimed</span><br>
                                                {{earnedSTAR(props.row.id)}} <span class="token">STAR</span>
                                        </div>
                                    </div>
                                    <div class="text-h7 col-12 justify-center flex q-pb-md q-mt-md">
                                        <div class="btn" :disabled="!isMetaMaskConnected"
                                            @click="selection(props.row.id)">
                                            {{getActionName(props.row.id)}}
                                        </div>
                                    </div>
                                    <div v-if="harvestInfo(props.row.id)" class="harvestInfo">
                                        <q-table
                                            id="farming"
                                            title=""
                                            class="transparent custom-table1"
                                            :data="filteredDeposite()"
                                            :columns="columns1"
                                            row-key="id"
                                            v-if="!isMobile"
                                            table-class="my-custom"
                                            :pagination.sync="pagination1"
                                            binary-state-sort
                                            :sort-method="mySort1"
                                        >
                                            <template v-slot:body="props">
                                                <q-tr>
                                                    <q-td class="text-bold text-center">
                                                        {{getLockedDate(props.row.id)}} 
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getStakedToken(props.row.id)}} 
                                                        <span class="token">{{getPairName(currentPoolIndex)}}</span>
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getTotalEarned(props.row.id)}} 
                                                        <span class="token">STAR</span>
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getTax(props.row.id)}} 
                                                        <span class="token">%</span>
                                                    </q-td>
                                                    <q-td>
                                                        <q-btn
                                                            class="q-mt-sm, q-my-sm harvest"
                                                            color="white"
                                                            no-caps
                                                            label="Harvest"
                                                            flat
                                                            dense
                                                            :disabled="!isClaimable(props.row.id)" 
                                                            @click="harvest(props.row.id)"
                                                        />
                                                    </q-td>
                                                </q-tr>
                                            </template>
                                        </q-table>
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" style="margin:30px; ">
                                <q-td colspan="5" style="border-radius:20px;" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-pa-sm text-bold" 
                                        style="font-size 0.5rem;">
                                        <div style="width:40%">
                                            <h3>
                                                <div class="q-mb-sm label">LP Balance</div>
                                                <div class="balanceBtn token" @click="setPercent(3, 0, props.row.id)">
                                                    {{getLPBalance(props.row.id)}} 
                                                    <span class="token">{{getPairName(props.row.id)}}</span>
                                                </div>
                                            </h3>
                                            <div class="btnBrd sol">
                                                <div class="farm">
                                                    <q-input type="text" v-model="amountStake[props.row.id]"
                                                        :key="'input'+rerender"
                                                        standout="bg-indigo-5" placeholder="0.00"
                                                        :error-message="errorMessage1(props.row.id)" 
                                                        :error="errorMessage1(props.row.id)!=null" 
                                                        :bottom-slots="false" :hide-bottom-space="true">
                                                        <template v-slot:prepend>

                                                            <div class="dropdown">
                                                                <button @click="showPercent(0, props.row.id)" 
                                                                    class="dropbtn1 mx-2 dropdown-toggle">
                                                                    {{getCurrentPercent(props.row.id)}}%</button>
                                                                
                                                                <div :id="getPercentId(props.row.id)" class="percent dropdown-content1" 
                                                                    v-click-outside="onClickOutside1">
                                                                    <ul>
                                                                        <li @click="setPercent(0, 0, props.row.id)">25%</li>
                                                                        <li @click="setPercent(1, 0, props.row.id)">50%</li>
                                                                        <li @click="setPercent(2, 0, props.row.id)">75%</li>
                                                                        <li @click="setPercent(3, 0, props.row.id)">100%</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </q-input>
                                                </div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="!isApproved(props.row.id)" @click="approve(props.row.id)">Approve</div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="isApproved(props.row.id)" @click="stake(props.row.id)">&nbsp;&nbsp;Stake&nbsp;&nbsp;</div>
                                            </div>
                                        </div>
                                        <div style="width:40%">
                                            <h3>
                                                <div class="q-mb-sm label">Deposited</div>
                                                <div class="balanceBtn token" @click="setPercent(3, 1, props.row.id)">
                                                    {{getUnstakeBalance(props.row.id)}} 
                                                    <span class="token">{{getPairName(props.row.id)}}</span>
                                                    <span> (${{getDepositedDollar(props.row.id)}})</span>
                                                </div>
                                            </h3>
                                            <div class="btnBrd sol">
                                                <div class="farm">
                                                    <q-input type="text" v-model="amountUnstake[props.row.id]"
                                                        :key="'input'+rerender"
                                                        standout="bg-indigo-5" placeholder="0.00"
                                                        :error-message="errorMessage2(props.row.id)" :error="errorMessage2(props.row.id)!=null" 
                                                        :bottom-slots="false" :hide-bottom-space="true"
                                                        style="border-radius:10px">
                                                        <template v-slot:prepend>
                                                            <div class="dropdown">
                                                                <button @click="showPercent(1, props.row.id)" 
                                                                    class="dropbtn2 mx-2 dropdown-toggle">
                                                                    {{getCurrentPercent1(props.row.id)}}%</button>
                                                                
                                                                <div :id="getPercentId1(props.row.id)" class="percent dropdown-content2" 
                                                                    v-click-outside="onClickOutside2">
                                                                    <ul>
                                                                        <li @click="setPercent(0, 1, props.row.id)">25%</li>
                                                                        <li @click="setPercent(1, 1, props.row.id)">50%</li>
                                                                        <li @click="setPercent(2, 1, props.row.id)">75%</li>
                                                                        <li @click="setPercent(3, 1, props.row.id)">100%</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </q-input>
                                                </div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="!isApproved(props.row.id)" @click="approve(props.row.id)">Approve</div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="isApproved(props.row.id)" @click="unstake(props.row.id)">UnStake</div>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>
                                                <div class="q-mb-sm">&nbsp;</div>
                                                <div>
                                                    &nbsp;
                                                </div>
                                            </h3>
                                            <div>
                                                <router-link :to="getLiquidityURL(props.row.id)">
                                                    <div class="btn sub q-mx-sm" title="Add liquidity" 
                                                        style="font-weight:bold; font-size:25px; padding:2px 15px 4px 15px;">
                                                        +
                                                    </div>
                                                </router-link>
                                                <router-link :to="getSwapURL(props.row.id)">
                                                    <div class="btn sub" title="Swap" style="padding:11px 15px">
                                                        <img src="@/assets/images/swap.svg">
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                        <q-table
                            title=""
                            :dense="$q.screen.lt.md"
                            class="transparent custom-table"
                            :data="filteredFarm()"
                            :columns="columnsMobile"
                            row-key="id"
                            v-if="isMobile"
                            hide-header
                            :pagination.sync="pagination"
                            hide-bottom
                            binary-state-sort
                            :sort-method="mySort"
                        >
                        <template v-slot:body="props">
                            <q-tr v-show="!props.expand" :props="props" 
                                    @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" 
                                class="cursor-pointer">
                                <q-td>
                                    <div class="star flex items-center no-wrap">
                                        <span class="mr-2 avatar">
                                            <img :src="getLogo(0, props.row.id)" alt="">
                                        </span>
                                        <span class="usdc avatar">
                                            <img :src="getLogo(1, props.row.id)" alt="">
                                        </span>
                                    </div>
                                    <div style="font-size:0.6rem;">
                                        {{getPairName(props.row.id)}}
                                    </div>
                                </q-td>
                                <q-td class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">Total Reward</span><br>
                                    {{pendingSTAR(props.row.id)}} <span style="color:#abc4ff80">STAR</span>
                                </q-td>
                                <q-td class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">TVL</span><br>
                                    ${{getTVL(props.row.id).toFormat(2)}}
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" 
                                    @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" >
                                <q-td>
                                    <div class="star flex items-center no-wrap">
                                        <span class="mr-2 avatar">
                                            <img :src="getLogo(0, props.row.id)" alt="">
                                        </span>
                                        <span class="usdc avatar">
                                            <img :src="getLogo(1, props.row.id)" alt="">
                                        </span>
                                    </div>
                                    <div style="font-size:0.5rem;">
                                        {{getPairName(props.row.id)}}
                                    </div>
                                    <div style="font-size:0.5rem; margin-left:10px; margin-top:10px;">
                                         <span style="color:#abc4ff80">&nbsp;</span><br>
                                        &nbsp;
                                    </div>
                                </q-td>
                                <q-td colspan="2" class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">Total Reward</span><br>
                                    {{pendingSTAR(props.row.id)}} <span style="color:#abc4ff80">STAR</span>
                                    <div class="row">
                                        <div class="col-6" style="margin-top:10px; padding-left:0">
                                            <span style="color:#abc4ff80">Total Supply</span><br>
                                            {{getTotalLPSupply(props.row.id)}} LP
                                        </div>
                                        <div class="col-6" style="margin-top:10px;">
                                            <span style="color:#abc4ff80">APR</span><br>
                                            {{getAPR(props.row.id).toFormat(2)}}%
                                        </div>
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props">
                                <q-td colspan="5" style="border-radius:10px;" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-mt-sm text-bold"
                                            style="padding:0;">
                                        <div style="width:100%">
                                            <div class="flex">
                                                <div style="width:35%"><span class="label">Total Reward</span></div>
                                                <div style="width:65%">{{pendingSTAR(props.row.id)}} 
                                                    <span class="token">STAR</span>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <div style="width:35%"><span class="label" >Reward After Tax</span></div>
                                                <div style="width:65%">{{claimableSTAR(props.row.id)}} 
                                                    <span class="token">STAR</span>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <div style="width:35%"><span class="label" >Reward Claimed</span></div>
                                                <div style="width:65%">{{earnedSTAR(props.row.id)}} 
                                                    <span class="token">STAR</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-h7 col-12 justify-center flex q-py-sm">
                                        <div class="btn" :disabled="!isMetaMaskConnected"
                                            @click="selection(props.row.id)">
                                            {{getActionName(props.row.id)}}
                                        </div>
                                    </div>
                                    <div v-if="harvestInfo(props.row.id)" class="harvestInfo">
                                        <q-table
                                            id="farming"
                                            title=""
                                            :dense="$q.screen.lt.md"
                                            class="transparent custom-table1"
                                            :data="filteredDeposite()"
                                            :columns="columns1Mobile"
                                            row-key="id"
                                            v-if="isMobile"
                                            table-class="my-custom"
                                            hide-header
                                            :pagination.sync="pagination1"
                                            binary-state-sort
                                            :sort-method="mySort1"
                                        >
                                        <template v-slot:body="props">
                                            <q-tr v-show="!props.expand" :props="props" 
                                                @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" 
                                                        class="cursor-pointer">
                                                <q-td class="text-bold" style="font-size:0.6rem;">
                                                    <span style="color: rgb(255, 255, 0, 0.8);margin-top:15px;">Locked Date</span><br>
                                                    {{getLockedDate(props.row.id)}} 
                                                </q-td>
                                                <q-td class="text-bold" style="font-size:0.6rem;">
                                                    <span style="color: rgb(255, 255, 0, 0.8);q-py-sm">Staked Supply</span><br>
                                                    {{getStakedToken(props.row.id)}} 
                                                    <span style="color:#abc4ff80">LP</span>
                                                </q-td>
                                            </q-tr>
                                            <q-tr v-show="props.expand" :props="props" 
                                                @click.native="isMetaMaskConnected ? props.expand = !props.expand : null">
                                                <q-td colspan="2" class="harvestBtn">
                                                    <div class="flex">
                                                        <div class="q-mr-md">
                                                            <span style="color: rgb(255, 255, 0, 0.8);">Locked Date</span><br>
                                                            {{getLockedDate(props.row.id)}} 
                                                            <div style="margin-top:10px;">
                                                                <span style="color : rgb(255, 255, 0, 0.8);">Star After Tax</span><br>
                                                                {{getTotalEarned(props.row.id)}}<span style="color:#abc4ff80"> STAR</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span style="color: rgb(255, 255, 0, 0.8);">Staked Supply</span><br>
                                                            {{getStakedToken(props.row.id)}} 
                                                            <span style="color:#abc4ff80">{{getPairName(currentPoolIndex)}}</span>
                                                            <div style="margin-top:10px;">
                                                                <span style="color : rgb(255, 255, 0, 0.8);">Tax</span><br>
                                                                {{getTax(props.row.id)}}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="text-center">
                                                        <q-btn
                                                            class="q-mt-sm, q-mx-sm, q-mt-sm q-px-sm harvest"
                                                            color="white"
                                                            no-caps
                                                            label="Harvest"
                                                            flat
                                                            dense
                                                            :disabled="!isClaimable(props.row.id)" 
                                                            @click="harvest(props.row.id)"
                                                        />
                                                    </div>
                                                </q-td>
                                            </q-tr>
                                        </template>
                                        </q-table>
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props">
                                <q-td colspan="5" style="border-radius:10px; padding:0" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-mt-sm text-bold">
                                        <div style="width:100%;">
                                            <h3>
                                                <div class="q-mb-sm"><small>LP Balance</small></div>
                                                <div class="balanceBtn" @click="setPercent(3, 0, props.row.id)" 
                                                    style="font-size :0.7rem;">
                                                    {{getLPBalance(props.row.id)}} 
                                                    <span class="token">{{getPairName(props.row.id)}}</span>
                                                </div>
                                            </h3>
                                            <div class="btnBrd sol">
                                                <div class="farm">
                                                    <q-input type="text" v-model="amountStake[props.row.id]"
                                                        :key="'input'+rerender"
                                                        standout="bg-indigo-5" placeholder="0.00"
                                                        :error-message="errorMessage1(props.row.id)" 
                                                        :error="errorMessage1(props.row.id)!=null" 
                                                        :bottom-slots="false" :hide-bottom-space="true">
                                                        <template v-slot:prepend>
                                                            <!-- <button class="mx-2" style="padding:5px 15px;"  
                                                                @click="getMaxStake(props.row.id)">Max</button> -->
                                                            <div class="dropdown">
                                                                <button @click="showPercent(0, props.row.id)" 
                                                                    class="dropbtn1 mx-2 dropdown-toggle">
                                                                    {{getCurrentPercent(props.row.id)}}%</button>
                                                                
                                                                <div :id="getPercentId(props.row.id)" class="percent dropdown-content1" 
                                                                    >
                                                                    <ul>
                                                                        <li @click="setPercent(0, 0, props.row.id)">25%</li>
                                                                        <li @click="setPercent(1, 0, props.row.id)">50%</li>
                                                                        <li @click="setPercent(2, 0, props.row.id)">75%</li>
                                                                        <li @click="setPercent(3, 0, props.row.id)">100%</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </q-input>
                                                </div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="!isApproved(props.row.id)" @click="approve(props.row.id)">Approve</div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="isApproved(props.row.id)" @click="stake(props.row.id)">&nbsp;&nbsp;Stake&nbsp;&nbsp;</div>
                                            </div>
                                        </div>
                                        <div style="width:100%; margin-top:20px;">
                                            <h3>
                                                <div class="q-mb-sm"><small>Deposited</small></div>
                                                <div class="balanceBtn" @click="setPercent(3, 1, props.row.id)" 
                                                    style="font-size :0.7rem;">
                                                    {{getUnstakeBalance(props.row.id)}} 
                                                    <span class="token">{{getPairName(props.row.id)}}</span>
                                                    <span> (${{getDepositedDollar(props.row.id)}})</span>
                                                </div>
                                            </h3>
                                            <div class="btnBrd sol">
                                                <div class="farm">
                                                    <q-input type="text" v-model="amountUnstake[props.row.id]"
                                                        :key="'input'+rerender"
                                                        standout="bg-indigo-5" placeholder="0.00"
                                                        :error-message="errorMessage2(props.row.id)" :error="errorMessage2(props.row.id)!=null" 
                                                        :bottom-slots="false" :hide-bottom-space="true"
                                                        style="border-radius:10px">
                                                        <template v-slot:prepend>
                                                            <!-- <button class="mx-2" style="padding:5px 15px;" @click="getMaxUnstake(props.row.id)">Max</button> -->
                                                            <div class="dropdown">
                                                                <button @click="showPercent(1, props.row.id)" 
                                                                    class="dropbtn2 mx-2 dropdown-toggle">
                                                                    {{getCurrentPercent1(props.row.id)}}%</button>
                                                                
                                                                <div :id="getPercentId1(props.row.id)" class="percent dropdown-content2" 
                                                                    >
                                                                    <ul>
                                                                        <li @click="setPercent(0, 1, props.row.id)">25%</li>
                                                                        <li @click="setPercent(1, 1, props.row.id)">50%</li>
                                                                        <li @click="setPercent(2, 1, props.row.id)">75%</li>
                                                                        <li @click="setPercent(3, 1, props.row.id)">100%</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </q-input>
                                                </div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="!isApproved(props.row.id)" @click="approve(props.row.id)">Approve</div>
                                                <div class="btn" :disabled="!isMetaMaskConnected" v-if="isApproved(props.row.id)" @click="unstake(props.row.id)">UnStake</div>
                                            </div>
                                        </div>
                                        <div style="width:100%">
                                            <div class="text-center q-my-sm">
                                                <router-link :to="getLiquidityURL(props.row.id)">
                                                    <div class="btn" title="Add liquidity" 
                                                        style="margin-right:15px; font-size:14px; padding: 9px 13px; margin-top:5px">
                                                        +
                                                    </div>
                                                </router-link>
                                                <router-link :to="getSwapURL(props.row.id)">
                                                    <div class="btn sub" title="Swap">
                                                        <img src="@/assets/images/swap.svg" style="width:15px">
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                    </div>
                </div>                
            </div>
        </div>
    </section>
</template>
<script>
  import BigNumber from 'bignumber.js';
  import vClickOutside from 'v-click-outside'
  import { sortDate } from '../utils/sort.js'
  import { isNumber, isDate, isObject } from '../utils/is.js'
  export default {
    name: "farm",
    data() {
        return {
            rerender: 0,
            percents: [
                '25', '50', '75', '100'
            ],
            currentPercent: [],
            currentPercent1: [],
            filter: 'all',
            pagination: {
                rowsPerPage: 30
            },
            pagination1: {
                rowsPerPage: 10
            },
            columns: [
                {
                    name: 'farm',
                    field: 'farm',
                    label: 'Farm',
                    align: 'center',
                },
                {
                    name: 'totalReward',
                    field: 'totalReward',
                    label: 'Total Reward',
                    align: 'right',
                    sortable: true
                },
                {
                    name: 'tvl',
                    field: 'tvl',
                    label: 'TVL',
                    align: 'right',
                    sortable: true
                },
                {
                    name: 'apr',
                    field: 'apr',
                    label: 'APR',
                    align: 'right',
                    sortable: true
                },
                {
                    name: 'arrow',
                    field: 'arrow',
                    label: '',
                    align: 'center',
                },
            ],
            columnsMobile: [
                {
                    name: 'farm',
                    field: 'farm',
                    label: 'Farm',
                    align: 'left',
                },
                {
                    name: 'totalReward',
                    field: 'totalReward',
                    label: 'Total Reward',
                    align: 'left',
                    sortable: true
                },
                {
                    name: 'tvl',
                    field: 'tvl',
                    label: 'TVL',
                    align: 'left',
                    sortable: true
                },
            ],
            amountStake: [],
            amountUnstake: [],
            showModal: false,
            currentPoolIndex: null,
            previousPoolIndex: null,
            currentPoolId: null,
            showHarvestInfo: [],
            columns1: [
                {
                    name: 'Date',
                    field: 'date',
                    label: 'LOCKED DATE',
                    align: 'center',
                    sortable: true
                },
                {
                    name: 'staked token',
                    field: 'stakedToken',
                    label: 'STAKED SUPPLY',
                    align: 'center',
                    sortable: true
                },
                {
                    name: 'total earned',
                    field: 'totalEarned',
                    label: 'STAR AFTER TAX',
                    align: 'center',
                    sortable: true
                },
                {
                    name: 'tax',
                    field: 'tax',
                    label: 'TAX ( % )',
                    align: 'center',
                    sortable: true
                },
                {
                    name: 'action',
                    field: 'action',
                    label: '',
                    align: 'left',
                },
            ],
            columns1Mobile: [
                {
                    name: 'Date',
                    field: 'date',
                    label: 'LOCKED DATE',
                    align: 'center',
                    sortable: true
                },
                {
                    name: 'staked token',
                    field: 'stakedToken',
                    label: 'STAKED SUPPLY',
                    align: 'right',
                    sortable: true
                },
            ],
            percentIDs: [],
            percent1IDs: []
        };
    },
    mounted() {
        let length = this.$store.state.liquidity.farm_list.length;
        for (let i=0; i<length; i++) {
            this.percentIDs[i] = "percent_" + i
            this.percent1IDs[i] = "percent1_" + i
            this.showHarvestInfo[i] = false;
        }
    },
    computed: {
        isMobile() {
            return this.$q.screen.width < 996;
        },
        isMetaMaskConnected() {
            return this.$store.state.account!=null;
        },

    },
    methods: {
        onClickOutside (event) {
            this.showModal = false;
        },
        harvestInfo(index) {
            return this.showHarvestInfo[index];
        },
        filteredFarm() {
            var rows = [];
            let length = this.$store.state.liquidity.farm_list.length;
            for (let i = 0; i < length; i++) {
                rows[i] = {
                    id : i,
                    totalReward: this.pendingSTAR_Number(i),
                    tvl: this.getTVL(i),
                    apr: this.getAPR(i),
                }
            }

            return rows
        },
        getLogo(kind, index) {
            if (kind == 0) {
                let tokenInfo = this.$store.state.liquidity.tokenMap
                    .get(this.$store.state.liquidity.farm_list[index].symbol0);
                return tokenInfo.logo;
            }
            else {
                let tokenInfo = this.$store.state.liquidity.tokenMap
                    .get(this.$store.state.liquidity.farm_list[index].symbol1);
                return tokenInfo.logo;
            }
        },
        getPairName(index) {
            return this.$store.state.liquidity.farm_list[index].symbol0 + "-" 
                            + this.$store.state.liquidity.farm_list[index].symbol1
        },
        getLiquidityURL(index) {
            return "/liquidity/0" + "/" + this.$store.state.liquidity.farm_list[index].symbol0 + "/" 
                                  + this.$store.state.liquidity.farm_list[index].symbol1
        },
        getSwapURL(index) {
            return "/swap/" + this.$store.state.liquidity.farm_list[index].symbol0 + "/" 
                            + this.$store.state.liquidity.farm_list[index].symbol1
        },
        forceUpdate() {
            this.rerender++;
        },
        filteredDeposite() {
            var rows = [];
            let length = this.$store.state.farming.depositInfo.length;
            for (let i = 0; i < length; i++) {
                rows[i] = {
                    id : i,
                    date: this.getLockedDate(i),
                    stakedToken: this.getStakedToken_Number(i),
                    totalEarned: this.getTotalEarned_Number(i),
                    tax: this.getTax(i),
                }
            }
            return rows
        },
        errorMessage1(index) {
            if(BigNumber(this.amountStake[index]).isGreaterThan(BigNumber(this.maxStake(index)))) {
                return "INSUFFICIENT ACCOUNT BALANCE";
            }
            return null;
        },
        errorMessage2(index) {
            if(BigNumber(this.amountUnstake[index]).isGreaterThan(BigNumber(this.maxUnstake(index)))) {
                return "INSUFFICIENT ACCOUNT BALANCE";
            }
            return null;
        },
        isApproved(index) {
            return this.$store.state.approvedPairMaster[index];
        },
        approve(index) {
            let lp = this.$store.state.liquidity.farm_list[index].address;
            this.$store.dispatch('approvePairMaster', {
                address:lp,
                index: index
            });

            this.forceUpdate();
        },
        stake(index) {
            let poolId = this.$store.state.liquidity.farm_list[index].poolId;
            this.$store.dispatch('deposit',{
                index:poolId,
                amount:this.amountStake[index],
                decimals: 18,
                staking: false,
                currentOpenedPoolIndex: index
            });

            this.forceUpdate();
        },
        unstake(index) {
            let poolId = this.$store.state.liquidity.farm_list[index].poolId;
            this.$store.dispatch('withdraw',{
                index:poolId,
                amount:this.amountUnstake[index],
                decimals: 18,
                staking: false,
                currentOpenedPoolIndex: index
            });

            this.forceUpdate();
        },
        harvest(index) {
            this.$store.dispatch('harvest', {
                pid: this.currentPoolId,
                depositIndex: index,
                staking: false,
                currentOpenedPoolIndex: index
            }).then((ret)=>{
                if (ret.blockHash != null) {
                    var myTable = document.getElementById('farming').getElementsByClassName('q-table')[0];
                    myTable.rows[index+1].cells[2].innerHTML = '0.00';
                    myTable.rows[index+1].cells[4].getElementsByClassName('q-btn')[0].disabled = true;
                }
            });
        },
        getActionName(index) {
            if (this.showHarvestInfo[index])
                return "Hide Harvest List"
            else
                return "Show Harvest List"
        },
        selection(index) {
            if (this.showHarvestInfo[index]) {
                this.showHarvestInfo[index] = false;

                this.forceUpdate();

                return;
            }
            let poolId = this.$store.state.liquidity.farm_list[index].poolId;
            this.$store.dispatch('getDepositExtraInfoInFarming', poolId).then(()=>{
                // this.showModal = !this.showModal;
                this.previousPoolIndex = this.currentPoolIndex;
                this.currentPoolIndex = index;
                this.currentPoolId = poolId;

                if (this.currentPoolIndex != this.previousPoolIndex)
                    this.showHarvestInfo[this.previousPoolIndex] = false;

                this.showHarvestInfo[index] = true;

                this.forceUpdate();
            });
        },
        getDate(amount) {
            let date = amount * 1000;
            const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
            const dtf = new Intl.DateTimeFormat('en-US', options);
            return dtf.format(date);
        },
        getTVL(index) {
            if (this.$store.state.farming.tvl[index] > 0)
                return BigNumber(this.$store.state.farming.tvl[index]);

            return BigNumber(0);
        },
        getAPR(index) {
            if (this.$store.state.farming.totalSupply[index] == 0)
                return BigNumber(0);

            let lpPrice = BigNumber(this.$store.state.farming.tvl[index])
                    .div(BigNumber(this.$store.state.farming.liquiditybalance[index]).shiftedBy(-18));

            let apr = BigNumber(10).times(1*24*3600*365/12)      // 5 tokens per 12s
                .times(this.$store.state.farming.allocpoint[index])
                .div(this.$store.state.farming.totalAllocPoint)
                .times(this.$store.state.price.STAR)
                .div(
                    BigNumber(this.$store.state.farming.totalSupply[index]).shiftedBy(-18)
                        .times(lpPrice)
                )
                .times(100);

            if (apr > 0)
                return BigNumber(apr);
            else
                return BigNumber(0);
        },
        maxStake(index) {
            if(this.$store.state.account && this.$store.state.farming.tokenbalance[index] > 0)
                return BigNumber(this.$store.state.farming.tokenbalance[index])
                    .shiftedBy(-18);               
            else
                return BigNumber(0);
        },
        getLPBalance(index) {
            if(this.$store.state.account && this.$store.state.farming.tokenbalance[index] > 0)
                return BigNumber(this.$store.state.farming.tokenbalance[index])
                    .shiftedBy(-18);               
            else
                return '0.00';
        },
        maxUnstake(index) {
            if (this.$store.state.account && this.$store.state.farming.balance[index] > 0)
                return BigNumber(this.$store.state.farming.balance[index])
                        .shiftedBy(-18);
            else
                return BigNumber(0);
        },
        getUnstakeBalance(index) {
            if(this.$store.state.account && this.$store.state.farming.balance[index] > 0)
                return this.getDecimalData(BigNumber(this.$store.state.farming.balance[index])
                    .shiftedBy(-18));               
            else
                return '0.00';
        },
        getDepositedDollar(index) {
            if (this.$store.state.account && this.$store.state.farming.balance[index] > 0) {
                let lpPrice = BigNumber(this.$store.state.farming.tvl[index])
                    .div(BigNumber(this.$store.state.farming.liquiditybalance[index]).shiftedBy(-18));

                return this.getDecimalData(BigNumber(this.$store.state.farming.balance[index]).shiftedBy(-18)
                        .times(lpPrice));
            }
            else
                return '0.00';
        },
        pendingSTAR(index) {
            let value = this.pendingSTAR_Number(index);
            return this.getDecimalData(value);
        },
        pendingSTAR_Number(index) {
            if(this.$store.state.account && this.$store.state.farming.pendingSTAR[index] > 0)
                return BigNumber(this.$store.state.farming.pendingSTAR[index])
                .shiftedBy(-18);

            return BigNumber(0);
        },
        claimableSTAR(index) {
            if(this.$store.state.account && this.$store.state.farming.claimableSTAR[index] > 0)
                return this.getDecimalData(BigNumber(this.$store.state.farming.claimableSTAR[index])
                .shiftedBy(-18));

            return '0.00';
        },
        earnedSTAR(index) {
            if(this.$store.state.account && this.$store.state.farming.earnedSTAR[index] > 0)
                return this.getDecimalData(BigNumber(this.$store.state.farming.earnedSTAR[index])
                .shiftedBy(-18));

            return '0.00';
        },
        isClaimable(index) {
            return (this.$store.state.farming.claimableTokenInfo[index] > 0)
        },
        getMaxStake(index) {
            // this.amountStake[index] = this.getDecimalData(BigNumber(this.$store.state.farming.tokenbalance[index])
            // .shiftedBy(-18));

            this.amountStake[index] = BigNumber(this.$store.state.farming.tokenbalance[index])
                                        .shiftedBy(-18);

            this.forceUpdate();
        },
        getMaxUnstake(index) {
            // this.amountUnstake[index] = this.getDecimalData(BigNumber(this.$store.state.farming.balance[index])
            //     .shiftedBy(-18));

            this.amountUnstake[index] = BigNumber(this.$store.state.farming.balance[index])
                                        .shiftedBy(-18);

            this.forceUpdate();
        },
        getTotalLPSupply(index) {
            if(this.$store.state.farming.liquiditybalance[index] > 0)
                return this.getDecimalData(BigNumber(this.$store.state.farming.liquiditybalance[index])
                .shiftedBy(-18));

            return '0.00';
        },
        getLockedDate(index) {
            return this.getDate(this.$store.state.farming.depositInfo[index][3]);
        },
        getStakedToken(index) {
            let value = this.getStakedToken_Number(index);
            return this.getDecimalData(value);
        },
        getStakedToken_Number(index) {
            let value = BigNumber(this.$store.state.farming.depositInfo[index][1]).shiftedBy(-18);

            return value;
        },
        getTotalEarned(index) {
            let value = this.getTotalEarned_Number(index);
            return this.getDecimalData(value);
        },
        getTotalEarned_Number(index) {
            let value = BigNumber(this.$store.state.farming.claimableTokenInfo[index]).shiftedBy(-18);

            return value;
        },

        getTax(index) {
            return this.$store.state.farming.taxInfo[index] / 100;
        },
        showPercent(index, id) {
            let percentId;
            if (index == 0)
                percentId = "percent_" + id;
            else if (index == 1)
                percentId = "percent1_" + id;

            document.getElementById(percentId).classList.toggle("show");
        },
        getCurrentPercent(index) {
            if (this.currentPercent[index] == null)
                return 100;
            return this.currentPercent[index];
        },
        getCurrentPercent1(index) {
            if (this.currentPercent1[index] == null)
                return 100;
            return this.currentPercent1[index];
        },
        setPercent(value, index, id) {
            if (index == 0) {
                this.currentPercent[id] = this.percents[value];
                if (value == 0)
                    this.amountStake[id] = BigNumber(this.$store.state.farming.tokenbalance[id]).div(4)
                                .shiftedBy(-18);
                else if (value == 1)
                    this.amountStake[id] = BigNumber(this.$store.state.farming.tokenbalance[id]).div(2)
                                .shiftedBy(-18);
                else if (value == 2)
                    this.amountStake[id] = BigNumber(this.$store.state.farming.tokenbalance[id]).times(3).div(4)
                                .shiftedBy(-18);
                else
                    this.amountStake[id] = BigNumber(this.$store.state.farming.tokenbalance[id])
                                .shiftedBy(-18);

                if (this.amountStake[id] == '0')
                    this.amountStake[id] = '0.00';
            }
            else if (index == 1) {
                this.currentPercent1[id] = this.percents[value];
                if (value == 0)
                    this.amountUnstake[id] = this.getDecimalData(BigNumber(this.$store.state.farming.balance[id]).div(4)
                        .shiftedBy(-18));
                else if (value == 1)
                    this.amountUnstake[id] = this.getDecimalData(BigNumber(this.$store.state.farming.balance[id]).div(2)
                        .shiftedBy(-18));
                else if (value == 2)
                    this.amountUnstake[id] = this.getDecimalData(BigNumber(this.$store.state.farming.balance[id]).times(3).div(4)
                        .shiftedBy(-18));
                else
                    this.amountUnstake[id] = this.getDecimalData(BigNumber(this.$store.state.farming.balance[id])
                        .shiftedBy(-18));

                if (this.amountUnstake[id] == '0')
                    this.amountUnstake[id] = '0.00';
            }

            this.forceUpdate();

            let percentId;
            if (index == 0)
                percentId = "percent_" + id;
            else if (index == 1)
                percentId = "percent1_" + id;

            document.getElementById(percentId).classList.toggle("show");

        },
        onClickOutside1 (event) {
            if (!event.target.matches('.dropbtn1')) {
                let dropdowns = document.getElementsByClassName("dropdown-content1");
                let i;
                for (i = 0; i < dropdowns.length; i++) {
                    let openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        },
        onClickOutside2 (event) {
            if (!event.target.matches('.dropbtn2')) {
                let dropdowns = document.getElementsByClassName("dropdown-content2");
                let i;
                for (i = 0; i < dropdowns.length; i++) {
                    let openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        },
        getPercentId(index) {
            return this.percentIDs[index];
        },
        getPercentId1(index) {
            return this.percent1IDs[index];
        },
        getDecimalData(value) {
            let limit_xxx = BigNumber(1000);
            let limit_xx = BigNumber(1);
            let limit_x = BigNumber(1).shiftedBy(-3);
            let limit_sm = BigNumber(1).shiftedBy(-6);
            let limit_md = BigNumber(1).shiftedBy(-9);
            let limit_lg = BigNumber(1).shiftedBy(-12);

            if (limit_lg.isGreaterThan(value)) {
                return value.toString();
            }
            else if (limit_md.isGreaterThan(value)) {
                return value.decimalPlaces(12, 1).toString();
            }
            else if (limit_sm.isGreaterThan(value)) {
                return value.decimalPlaces(9, 1).toString();
            }
            else if (limit_x.isGreaterThan(value)) {
                return value.decimalPlaces(6, 1).toString();
            }
            else if (limit_xx.isGreaterThan(value)) {
                return value.decimalPlaces(4, 1).toString();
            }
            else if (limit_xxx.isGreaterThan(value)) {
                return value.decimalPlaces(2, 1).toString();
            }
            else {
                return value.decimalPlaces(0, 1).toString();
            }
        },
        mySort (data, sortBy, descending) {
            const col = this.columns.find(def => def.name === sortBy)
            if (col === null || col.field === void 0) {
                return data
            }

            const
                dir = descending ? -1 : 1,
                val = typeof col.field === 'function'
                ? v => col.field(v)
                : v => v[col.field]

            return data.sort((a, b) => {
                let
                    A = val(a),
                    B = val(b)

                if (A === null || A === void 0) {
                    return -1 * dir
                }
                if (B === null || B === void 0) {
                    return 1 * dir
                }
                if (col.sort) {
                    return col.sort(A, B) * dir
                }

                if (!isNaN(A) && !isNaN(B)) {
                    return (A - B) * dir
                }
                if (isDate(A) && isDate(B)) {
                    return sortDate(A, B) * dir
                }
                if (typeof A === 'boolean' && typeof B === 'boolean') {
                    return (a - b) * dir
                }

                [A, B] = [A, B].map(s => (s + '').toLowerCase())

                return A < B
                    ? -1 * dir
                    : (A === B ? 0 : dir)
            })
        },
        mySort1 (data, sortBy, descending) {
            const col = this.columns1.find(def => def.name === sortBy)
            if (col === null || col.field === void 0) {
                return data
            }

            const
                dir = descending ? -1 : 1,
                val = typeof col.field === 'function'
                ? v => col.field(v)
                : v => v[col.field]

            return data.sort((a, b) => {
                let
                    A = val(a),
                    B = val(b)

                if (A === null || A === void 0) {
                    return -1 * dir
                }
                if (B === null || B === void 0) {
                    return 1 * dir
                }
                if (col.sort) {
                    return col.sort(A, B) * dir
                }

                if (!isNaN(A) && !isNaN(B)) {
                    return (A - B) * dir
                }
                if (isDate(A) && isDate(B)) {
                    return sortDate(A, B) * dir
                }
                if (typeof A === 'boolean' && typeof B === 'boolean') {
                    return (a - b) * dir
                }

                [A, B] = [A, B].map(s => (s + '').toLowerCase())

                return A < B
                    ? -1 * dir
                    : (A === B ? 0 : dir)
            })
        }
    }
  }
</script>

<style>
    tr.spaceUnder>td {
        padding-bottom: 10em !important;
    }

    .tooltip2 {
        position: relative;
        display: inline-block;
    }

    .tooltip2 p {
        font-weight: 500;
        color:rgba(255,255,255,0.7) !important;
    }
    .tooltip2 .tooltiptext {
        visibility:hidden;
        background-color: #131a35;
        border-radius: 7px;
        padding: 15px;
        position: absolute;
        z-index: 1;
        top: -50px;
        left: 140px;
        width:250px;
        text-align: left !important;
    }

    .tooltip2 .tooltiptext::after {
        content: "";
        position: absolute;/* To position the tooltip */
        top: 40%;
        left: 0%;
        margin-left: -15px;

        /* To add an arrow */
        border: 8px solid;
        border-color: #131a35 transparent transparent transparent;
        transform: rotate(90deg);
    }
    .tooltip2:hover .tooltiptext {
        visibility: visible;
    }

</style>
